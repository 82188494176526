@charset "UTF-8";

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px;
}

.grid-item {
  padding: 2px;
  text-align: center;
}
