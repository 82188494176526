.scenario-calendar {
  background-color: unset;
  color: #fff;
  border: 0;
  font-family: inherit;
}

.scenario-calendar .react-datepicker__header {
  background-color: unset;
  border: 0;
}

.scenario-calendar .react-datepicker__current-month {
  color: var(--chakra-colors-textPrimary);
  text-align: left;
  padding-left: 1rem;
}

.scenario-calendar .react-datepicker__navigation.react-datepicker__navigation {
  top: 12px;
}

.scenario-calendar
  .react-datepicker__navigation.react-datepicker__navigation--previous {
  left: unset;
  right: 2.5rem;
}

.scenario-calendar .react-datepicker__navigation-icon::before,
.scenario-calendar .react-datepicker__year-read-view--down-arrow,
.scenario-calendar .react-datepicker__month-read-view--down-arrow,
.scenario-calendar .react-datepicker__month-year-read-view--down-arrow {
  height: 4px;
  width: 4px;
}

.scenario-calendar .react-datepicker__day-names {
  padding-top: 10px;
}

.scenario-calendar .react-datepicker__day,
.scenario-calendar .react-datepicker__day-name {
  color: var(--chakra-colors-textSecondary);
  margin: 0;
  width: 2.25rem;
  line-height: 2.25rem;
  border-radius: 50%;
}

.scenario-calendar
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
  color: var(--chakra-colors-textSecondary);
  background-color: transparent;
}

.scenario-calendar .react-datepicker__day.react-datepicker__day--outside-month {
  color: var(--chakra-colors-textTertiary);
}

.scenario-calendar .react-datepicker__day:hover {
  color: var(--chakra-colors-textPrimary);
  background-color: var(--chakra-colors-whiteAlpha-200);
  border-radius: 50%;
}

.scenario-calendar .react-datepicker__day.react-datepicker__day--selected {
  background-color: var(--chakra-colors-primary-500);
}

.scenario-calendar .react-datepicker__day.react-datepicker__day--in-range {
  color: #000;
  background-color: var(--chakra-colors-primary-500);
  border-radius: 0;
}

.scenario-calendar
  .react-datepicker__day.react-datepicker__day--in-selecting-range {
  border-radius: 0;
  background-color: var(--chakra-colors-primary-600);
}

.scenario-calendar
  .react-datepicker__day.react-datepicker__day--selecting-range-start,
.scenario-calendar .react-datepicker__day.react-datepicker__day--range-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.scenario-calendar
  .react-datepicker__day.react-datepicker__day--selecting-range-end,
.scenario-calendar .react-datepicker__day.react-datepicker__day--range-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.scenario-calendar .react-datepicker__day.react-datepicker__day--selected,
.scenario-calendar
  .react-datepicker__day.react-datepicker__day--selecting-range-start,
.scenario-calendar
  .react-datepicker__day.react-datepicker__day--selecting-range-end,
.scenario-calendar .react-datepicker__day.react-datepicker__day--range-start,
.scenario-calendar .react-datepicker__day.react-datepicker__day--range-end,
.scenario-calendar .react-datepicker__day:hover {
  color: var(--chakra-colors-textPrimary);
}

.scenario-calendar .react-datepicker__day.react-datepicker__day--disabled {
  color: #000;
  background-color: unset;
}
