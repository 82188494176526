@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-VariableFont_wght.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Bold.ttf");
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Medium.ttf");
  font-display: swap;
  font-weight: medium;
}

* {
  font-family: "Poppins", sans-serif, Arial;
}

html,
body {
  background-color: black;

  /* Allow sticky header to work while still hiding X scrollbar */
  /*overflow-x: clip;*/
}

* {
  box-sizing: border-box;
}

.asset-zoom-fullscreen-component {
  display: flex;
  flex: 1;
}

.file-manager-selection-area {
  background-color: #42f2f22d;
  border: 1px solid #42f2f2;
}

button[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.checkerboard {
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-image: linear-gradient(
      45deg,
      #555 25%,
      /* darker shade */ transparent 25%,
      transparent 75%,
      #555 75%,
      /* darker shade */ #555 100% /* darker shade */
    ),
    linear-gradient(45deg, #555 25%, white 25%, white 75%, #555 75%, #555 100%);
}

/* For unknown reason, when using popover with portal there is a classname that set the zIndex to 10,
changing it to 1500 to make sure it is always on top
Things that didn't work:
- changing the zIndex of the portal in ChakraProvider props, create problem with other portals
- increasing the zIndex of the popover itself, do not go through overflow hidden in parents
 */
.chakra-popover__popper {
  z-index: 1700 !important;
}

/* width */
.custom-scrollbar::-webkit-scrollbar,
.custom-scrollbar *::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track,
.custom-scrollbar *::-webkit-scrollbar-track {
  background: #2d2d2d;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar *::-webkit-scrollbar-thumb {
  background-color: #ffffff22;
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover,
.custom-scrollbar *::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff33;
}
